'use client';
import { useState } from 'react';
import { clientFetch } from '@hxm/utils/clientFetch';

export type { Step2Result } from '@hugsmidjan-is/audkenni';

export const useAudkenniFetch = <T, D>(url: string) => {
  const [loading, setLoading] = useState(false);
  const fetchAudkenni = (data: T) => {
    if (loading) {
      return Promise.resolve(null);
    }
    setLoading(true);
    return clientFetch<D>(url, {
      method: 'POST',
      body: JSON.stringify(data),
    }).finally(() => {
      setLoading(false);
    });
  };

  return { fetchAudkenni, loading };
};

export default useAudkenniFetch;
