import React from 'react';
import classNames from 'classnames/bind';

import s from './Flex.module.scss';

const c = classNames.bind(s);

export type FlexProps = {
  children: React.ReactNode;
  columns?: 3 | 4;
  wrap?: boolean;
};

export const Flex = ({ children, columns = 3, wrap = false }: FlexProps) => {
  return (
    <div
      className={c(s.flex, {
        [s.col4]: columns === 4,
        wrap,
      })}
    >
      {children}
    </div>
  );
};
