import s from './LoginTabs.module.scss';

type IProps = {
  tabs: Array<{
    id: number;
    title: string;
  }>;
  setTab: (id: number) => void;
  active: number;
};

export const LoginTabs = ({ tabs, setTab, active }: IProps) => {
  return (
    <div role="tablist" className={s.loginTabs}>
      {tabs.map(({ id, title }) => (
        <button
          type="button"
          key={id}
          role="tab"
          onClick={() => setTab(id)}
          aria-selected={active === id ? 'true' : 'false'}
          aria-controls={`tab-${id}`}
          className={s.loginTabs__tab}
        >
          {title}
        </button>
      ))}
    </div>
  );
};
