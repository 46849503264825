import { Link } from '../link/Link';
import { Logo, LogoUrl } from '../logo/Logo';

import s from './HeaderLogin.module.scss';

type HeaderLoginProps = {
  logo?: string | null;
};
export const HeaderLogin = ({ logo }: HeaderLoginProps) => {
  return (
    <header className={s.headerLogin}>
      <div className={s.headerLogin__container}>
        <a tabIndex={0} className={s.headerLogin__skip} href="#main">
          Skip to content
        </a>

        <Link tabIndex={0} to="/" title="Forsíða" className={s.headerLogin__logo}>
          {logo ? <LogoUrl url={logo} /> : <Logo />}
        </Link>
      </div>
    </header>
  );
};
