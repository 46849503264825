import classnames from 'classnames/bind';

import s from './Message.module.scss';

const c = classnames.bind(s);

export type MessageVariant = 'info' | 'error' | 'error-left';

type IProps = {
  children: React.ReactNode;
  id: string | null;
  className?: string;
  messageVariant?: MessageVariant;
};

export const Message = ({
  children,
  id,
  className,
  messageVariant = 'error',
}: IProps) => {
  return (
    <div className={c(s.container, messageVariant)}>
      <div
        id={id === null ? undefined : id}
        className={c(s.message, className, messageVariant)}
      >
        {children}
      </div>
    </div>
  );
};
