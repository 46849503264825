'use client';
import { Step2Result } from '@hugsmidjan-is/audkenni';

const successRedirect = process.env.NEXT_PUBLIC_AUTH_REDIRECT_SUCCESS;

export type AuthResult =
  | {
      success: true;
      redirect: string;
    }
  | {
      success: false;
      message?: string;
    };

export type CheckAudkenniRouteResult = {
  failed: boolean;
  complete: boolean;
  reason?: string;
};
export type AuthCheckInput = Step2Result & { choice: 0 | 2 };

export const audkenniPoller = (
  data: AuthCheckInput,
  checkAuthPost: (data: AuthCheckInput) => Promise<CheckAudkenniRouteResult | null>,
  interval = 3000,
  maxPoll = 30
) => {
  return new Promise<AuthResult>((resolve, reject) => {
    let poll = 1;
    const statusPing = setInterval(() => {
      if (poll <= maxPoll) {
        checkAuthPost(data)
          .then((response) => {
            if (response?.failed) {
              clearInterval(statusPing);
              resolve({
                message: response.reason,
                success: false,
              });
            }
            if (response?.complete && !response.failed) {
              clearInterval(statusPing);
              resolve({
                success: true,
                redirect: successRedirect ?? '/',
              });
            }
          })
          .catch((e: Error) => {
            clearInterval(statusPing);
            reject({
              success: false,
              message: e.message,
            });
          });
      } else {
        clearInterval(statusPing);
        reject({
          success: false,
          message: 'Aðgerð rann út á tíma',
        });
      }
      poll++;
    }, interval);
  });
};
